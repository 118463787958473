import {
  InfoSectionEvent,
  IProductDTO,
  IProductPageControllerConfig,
  IProductPageStyleParams,
  IPropsInjectedByViewerScript,
  ProductPagePagination,
  ProductPagePaginationItem,
  ProdudctQunatityRange,
  SocialSharingEvent,
  TranslationDictionary,
  UserInput,
  UserInputData,
  UserInputErrors,
} from '../types/app-types';
import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {getTranslations, isWorker} from '@wix/wixstores-client-core/dist/es/src/viewer-script/utils';
import {
  defaultQuantity,
  ImageModeType,
  MULTILINGUAL_TO_TRANSLATIONS_MAP,
  productPageTrackEvent,
  trackEventMetaData,
  translationPath,
  UserInputType,
} from '../constants';
import {ProductService} from '../services/ProductService';
import {QuantityCalculator} from '../services/products/quantityCalculator';
import {userInputsFactory} from '../services/products/producUtils';
import * as _ from 'lodash';
import {ProductType} from '../graphql/queries-schema';
import {
  APP_DEFINITION_ID,
  PageMap,
  PubSubEvents,
  STORAGE_PAGINATION_KEY,
  StoresWidgetID,
  AddToCartActionOption,
} from '@wix/wixstores-client-core/dist/es/src/constants';
import {all, capitalizeFirstLetters} from '../commons/utils';
import {PubSubManager} from '@wix/wixstores-client-core/dist/es/src/pub-sub-manager/pubSubManager';
import {MultilingualService} from '@wix/wixstores-client-core/dist/src/multilingualService/multilingualService';
import {IAppSettings, IProductOptionSelection} from '@wix/wixstores-graphql-schema/dist/es/src/graphql-schema';
import {StructurePage, IControllerConfig} from '@wix/native-components-infra/dist/src/types/types';
import {parseUrl} from '@wix/native-components-infra/dist/src/urlUtils';
import {IStoreFrontNavigationContext} from '@wix/wixstores-client-core/dist/src/types/site-map';
import {ITrackEventParams} from '@wix/native-components-infra/dist/es/src/types/wix-sdk';
import {exposureEventForTestsParams, clickOnProductDetailsSfParams, socialButtonsParams} from '@wix/bi-logger-ec-sf';
import {ImageModeValues} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {updateWixCounters} from '../services/countersApi';
import {
  ProductPageItemData,
  SeoProductBuilder,
} from '@wix/wixstores-client-core/dist/es/src/builders/SeoItemData.builder';
import {IProductMediaItem} from '@wix/wixstores-graphql-schema';
import {queryToString} from '../services/urlUtils';
import {ISubscriptionPlan} from '../components/ProductOptions/SubscriptionPlan/SubscriptionPlan';

export class ProductPageStore {
  private media: IProductMediaItem[] = [];
  private product: IProductDTO;
  private readonly fedopsLogger;
  private readonly productService: ProductService;
  private translations: TranslationDictionary;
  public userInputs = userInputsFactory() as UserInput;
  private readonly pubSubManager: PubSubManager;
  private multilingualService: MultilingualService;
  private sectionUrl: string;
  private isStartReported: boolean = false;
  private isPhaseAppIntercativeCalled: boolean = false;
  private navigationContext: IStoreFrontNavigationContext;
  private translationsPromise: Promise<any>;
  private readonly publicData: IControllerConfig['publicData'];
  private addedToCartSuccessfullyCounter = 0;

  constructor(
    public styleParams: IProductPageStyleParams,
    public origPublicData: IProductPageControllerConfig['publicData'],
    private readonly setProps: Function,
    private readonly siteStore: SiteStore,
    private readonly externalId: string,
    private readonly reportError: (e) => any
  ) {
    this.pubSubManager = new PubSubManager(this.siteStore.pubSub);
    this.productService = new ProductService(siteStore, this.pubSubManager);
    this.navigationContext = this.getNavigationContext();
    this.publicData = _.cloneDeep(this.origPublicData);

    const fedopsLoggerFactory = this.siteStore.platformServices.fedOpsLoggerFactory;
    const fedopsArgs: any = {
      appId: APP_DEFINITION_ID,
      widgetId: StoresWidgetID.PRODUCT_PAGE,
    };
    if (this.siteStore.experiments.enabled('specs.stores.FedopsGlobalReporter')) {
      fedopsArgs.useGlobalLogger = true;
    }
    this.fedopsLogger = fedopsLoggerFactory.getLoggerForWidget(fedopsArgs);
    if (isWorker()) {
      this.fedopsLogger.appLoadStarted();
      this.isStartReported = true;
    }

    this.siteStore.location.onChange(() => {
      return this.setInitialState().catch(this.reportError);
    });
  }

  public async setInitialState(): Promise<void> {
    this.sectionUrl = (await this.siteStore.getSectionUrl(PageMap.PRODUCT)).url;

    const [translations, {product, appSettings}] = await all(
      this.getProductPageTranslations(),
      this.getInitialData()
    ).catch(this.reportError);

    if (!product) {
      if (this.siteStore.seo.isInSEO()) {
        this.siteStore.seo.setSeoStatusCode(404);
      }
      console.error('Slug is missing or invalid');
      return this.setProps({
        emptyState: true,
        ...this.defaultProps,
      });
    }
    this.product = product;
    this.media = product.media;
    this.translations = translations;
    this.multilingualService = new MultilingualService(
      this.publicData.APP || {},
      appSettings.widgetSettings,
      this.siteStore.getMultiLangFields(),
      this.siteStore.locale
    );
    this.productService.updateOptions(this.product);
    this.setInitialUserInputs();
    this.siteStore.pubSub.publish(PubSubEvents.RELATED_PRODUCTS, [this.product.id], true);
    await this.setInitialProps();
    await this.trackViewContent();
    this.setPageMetaData();
    if (this.siteStore.isSSR()) {
      this.fedopsLogger.appLoaded();
    }
  }

  private reportBIOnAppLoaded() {
    const type =
      this.styleParams.numbers.productPage_galleryImageMode === ImageModeValues.CROP
        ? ImageModeType.CROP
        : ImageModeType.FIT;
    const eventData: exposureEventForTestsParams = {
      isMobileFriendly: this.siteStore.isMobileFriendly,
      testName: 'product_page_loaded',
      is_eligible: true,
      type,
    };
    // tslint:disable no-floating-promises
    this.siteStore.biLogger.exposureEventForTests(eventData);
  }

  private reportToWiXCounters() {
    return updateWixCounters(this.siteStore, this.product.id, this.siteStore.uuid);
  }

  public onAppLoaded = () => {
    if (!isWorker() || (this.siteStore.isInteractive() && this.isStartReported)) {
      if (this.siteStore.isSiteMode()) {
        this.reportBIOnAppLoaded();
        this.product && this.reportToWiXCounters();
      }
      this.fedopsLogger.appLoaded();
      this.isStartReported = false;
      if (!this.product) {
        throw new Error('Slug is missing or invalid');
      }
    }
  };

  public onAppInteractive = () => {
    if (!isWorker() || !this.isPhaseAppIntercativeCalled) {
      this.fedopsLogger.appLoadingPhaseStart('appInteractive');
      this.isPhaseAppIntercativeCalled = true;
    }
  };

  private updatePublicData(newPublicData: IProductPageControllerConfig['publicData']) {
    Object.keys(newPublicData.APP).forEach(key => {
      this.publicData.APP[key] = newPublicData.APP[key];
    });
  }

  public updateState(
    newStyleParams: IProductPageStyleParams,
    newPublicData: IProductPageControllerConfig['publicData'] & {appSettings?: any}
  ): void {
    this.updatePublicData(newPublicData);
    this.styleParams = newStyleParams;
    this.multilingualService.setWidgetSettings(newPublicData.appSettings);
    this.setProps({
      texts: this.getTexts(),
    });
  }

  public onFocusTriggered = (): void => {
    this.setProps({
      shouldFocusAddToCartButton: false,
      shouldFocusNewAddToCartButton: false,
    });
  };

  private getTexts() {
    return Object.keys(MULTILINGUAL_TO_TRANSLATIONS_MAP).reduce(
      (acc, translationKey) => {
        const multiligualKey = MULTILINGUAL_TO_TRANSLATIONS_MAP[translationKey];
        const override = this.multilingualService.get(multiligualKey);
        if (override) {
          acc[translationKey] = override;
        }
        return acc;
      },
      {...this.translations}
    );
  }

  private async setInitialProps() {
    const props: IPropsInjectedByViewerScript = {
      ...this.defaultProps,
      emptyState: false,
      handleAddToCart: this.handleAddToCart,
      handleUserInput: this.handleUserInput,
      hasMultipleMedia: this.product.media.length > 1,
      hideNavigationUrls: !this.siteStore.isSiteMode(),
      infoSection: this.infoSection,
      isDesktop: this.siteStore.isDesktop(),
      isMobile: this.siteStore.isMobile(),
      isRTL: this.siteStore.isRTL(),
      isSSR: this.siteStore.isSSR(),
      isSEO: this.siteStore.seo.isInSEO(),
      navigate: this.navigate,
      onFocusTriggered: this.onFocusTriggered,
      pagePath: await this.pagePath(),
      pagination: this.getPrevNextProducts(),
      product: this.product,
      productUrl: this.productUrl,
      quantityRange: this.quantityRange,
      ravenUserContextOverrides: {id: this.siteStore.storeId, uuid: this.siteStore.uuid},
      selectedVariant: this.productService.options.selectedVariant,
      shouldFocusAddToCartButton: false,
      shouldFocusNewAddToCartButton: false,
      siteUrl: this.siteStore.location.baseUrl,
      socialSharing: this.socialSharing,
      texts: this.getTexts(),
      notifyProduct: this.notifyProductLoaded,
      userInputErrors: userInputsFactory() as UserInputErrors,
      userInputs: this.userInputs,
      validate: this.validate,
      addedToCartSuccessfully: false,
      shouldShowAddToCartSuccessAnimation: this.getAddToCartAction() === AddToCartActionOption.NONE,
      subscriptionPlans: this.getSubscriptionPlans(),
    };
    this.setProps(props);
    this.setDelayedProps(); // to do: remove me #EE-17414
  }

  private get defaultProps() {
    return {
      onAppInteractive: this.onAppInteractive,
      onAppLoaded: this.onAppLoaded,
      experiments: {
        newAddToCartButton: this.siteStore.experiments.enabled('specs.stores.NewAddToCartButton'),
        jpgExperiment: this.siteStore.experiments.enabled('specs.stores.SSRJpgImageForPng'),
        isEmbeddedScriptPlaceHolderEnabled: this.siteStore.experiments.enabled(
          'specs.stores.EmbeddedScriptPlaceHolder'
        ),
        isFedOpsInteractive: this.siteStore.experiments.enabled('specs.stores.FedOpsInteractive'),
        isMobileProductDescriptionPosition: this.siteStore.experiments.enabled(
          'specs.stores.MobileProductDescriptionPosition'
        ),
        isMobileNativeShareEnabled: this.siteStore.experiments.enabled('specs.stores.MobileNativeShare'),
        isSubscriptionPlansEnabled: this.siteStore.experiments.enabled('specs.stores.ProductSubscriptionsSF'),
        isLazySentryIntegration: this.siteStore.experiments.enabled('specs.stores.LazySentryIntegration'),
      },
      isResponsive: this.styleParams.booleans.responsive === true,
      isInteractive: this.siteStore.isInteractive(),
      cssBaseUrl: this.siteStore.baseUrls.productPageBaseUrl,
    };
  }

  private setDelayedProps(ms = 500) {
    setTimeout(async () => {
      this.navigationContext = this.getNavigationContext();
      this.setProps({
        pagePath: await this.pagePath(),
        pagination: this.getPrevNextProducts(),
      });
    }, ms);
  }

  private setInitialUserInputs() {
    const textFieldsLength = this.product.customTextFields ? this.product.customTextFields.length : 0;

    const selection = this.product.options.reduce((acc, option, i) => {
      const shouldPreselect = option.selections.length > 1;
      acc[i] = shouldPreselect ? null : option.selections[0];
      return acc;
    }, []);

    this.userInputs = {
      selection,
      text: Array(textFieldsLength).fill(null),
      quantity: [defaultQuantity],
      subscriptionPlan: [null],
    };

    this.updateSelections();
  }

  private readonly socialSharing = {
    onClick: (data: SocialSharingEvent) => {
      const socialSharingEventWithProduct: socialButtonsParams = {...data, productId: this.product.id};
      return this.siteStore.biLogger.socialButtons(socialSharingEventWithProduct);
    },
  };

  private readonly infoSection = {
    onActive: (BIEvent: InfoSectionEvent) => {
      const infoSectionEventWithProduct: clickOnProductDetailsSfParams = {...BIEvent, productId: this.product.id};
      return this.siteStore.biLogger.clickOnProductDetailsSf(infoSectionEventWithProduct);
    },
  };

  private getProductPageTranslations(): Promise<TranslationDictionary> {
    if (this.translationsPromise) {
      return this.translationsPromise;
    }
    this.translationsPromise = getTranslations(
      translationPath(this.siteStore.baseUrls.productPageBaseUrl, this.siteStore.locale)
    );
    return this.translationsPromise;
  }

  private readonly navigate = (product: ProductPagePaginationItem): void => {
    this.siteStore.navigate({
      sectionId: PageMap.PRODUCT,
      queryParams: undefined,
      state: product.partialUrl,
    });
  };

  private readonly handleUserInput = (inputType: UserInputType, data: UserInputData = null, index: number) => {
    this.userInputs[inputType][index] = data;
    this.updateSelections();
    this.nextProps();
  };

  private readonly updateSelections = () => {
    this.productService.options.updateSelections(this.product, this.userInputs.selection);
    this.filterMedia();
  };

  private readonly nextProps = (additionalProps = {} as Partial<IPropsInjectedByViewerScript>) => {
    const nextProps: Partial<IPropsInjectedByViewerScript> = {
      quantityRange: this.quantityRange,
      product: this.product,
      selectedVariant: this.productService.options.selectedVariant,
      userInputs: this.userInputs,
      ...additionalProps,
    };

    this.setProps(nextProps);
  };

  public readonly validate = (): void => {
    this.nextProps({
      userInputErrors: this.productService.validate(this.userInputs),
    });
  };

  private readonly handleAddToCart = async (): Promise<any> => {
    if (this.isInvalid()) {
      return;
    }
    this.fedopsLogger.interactionStarted('add-to-cart');

    await all(this.reportAddToCartBI(), this.trackAddToCart());

    const eventId = this.pubSubManager.subscribe(
      'Minicart.DidClose',
      () => {
        this.setProps({
          shouldFocusAddToCartButton: true,
          shouldFocusNewAddToCartButton: Math.random(),
        });

        this.pubSubManager.unsubscribe('Minicart.DidClose', eventId);
      },
      true
    );

    this.setProps({addedToCartSuccessfully: this.addedToCartSuccessfullyCounter++});
    return this.productService
      .addToCart(this.product, this.userInputs, this.getAddToCartAction())
      .then(() => this.fedopsLogger.interactionEnded('add-to-cart'));
  };

  private getAddToCartAction() {
    let addToCartAction = AddToCartActionOption.MINI_CART;
    if (this.styleParams.numbers.productPage_addToCartAction) {
      addToCartAction = this.styleParams.numbers.productPage_addToCartAction;
    } else if (
      this.siteStore.experiments.enabled('specs.stores.NewAddToCartButton') &&
      !this.styleParams.booleans.productPage_openMinicart
    ) {
      addToCartAction = AddToCartActionOption.NONE;
    } else if (!this.styleParams.booleans.productPage_openMinicart) {
      addToCartAction = AddToCartActionOption.CART;
    }
    return addToCartAction;
  }

  private readonly notifyProductLoaded = () => {
    return this.siteStore.windowApis.trackEvent(
      'productPageLoaded' as any,
      {
        productId: this.product.id,
        name: this.product.name,
        currency: this.siteStore.currency,
        price: this.product.price,
      } as any
    );
  };

  private async reportAddToCartBI() {
    const eventData = {
      appName: 'productPageApp',
      hasOptions: this.userInputs.selection.length > 0,
      productId: this.product.id,
      productType: this.product.productType as ProductType,
      origin: 'product-page',
      isNavigateCart: !this.styleParams.booleans.productPage_openMinicart || this.productService.shouldNavigateToCart(),
      navigationClick:
        this.getAddToCartAction() === AddToCartActionOption.MINI_CART && !this.productService.shouldNavigateToCart()
          ? 'mini-cart'
          : this.getAddToCartAction() === AddToCartActionOption.CART ||
            (this.productService.shouldNavigateToCart() && this.getAddToCartAction() !== AddToCartActionOption.NONE)
          ? 'cart'
          : 'none',
      quantity: Math.round(this.selectedQuantity),
    };

    return this.siteStore.biLogger.clickOnAddToCartSf(eventData);
  }

  private async trackAddToCart() {
    const variant = this.productService.options.selectedVariant || this.product;

    const params: ITrackEventParams = {
      ...trackEventMetaData,
      id: this.product.id,
      name: this.product.name,
      price: variant.comparePrice || variant.price,
      currency: this.siteStore.currency,
      quantity: this.selectedQuantity,
    };

    return this.siteStore.windowApis.trackEvent(productPageTrackEvent.AddToCart, params);
  }

  private async trackViewContent() {
    const params: ITrackEventParams = {
      ...trackEventMetaData,
      id: this.product.id,
      name: this.product.name,
      price: this.product.comparePrice || this.product.price,
      currency: this.siteStore.currency,
    };

    return this.siteStore.windowApis.trackEvent(productPageTrackEvent.ViewContent, params);
  }

  private readonly isInvalid = () => {
    const validations = this.productService.validate(this.userInputs);

    return Object.keys(validations).reduce((accValidation: boolean, currentKey: string) => {
      const flag = validations[currentKey].some(value => value === true);
      return accValidation || flag;
    }, false);
  };

  private filterMedia(): void {
    const filteredMedia = _.flatMap(this.userInputs.selection, item => (item && item.linkedMediaItems) || []);

    this.product.media = filteredMedia.length ? (filteredMedia as any) : this.media;
  }

  private getUrlWithoutParams(url: string): string {
    const parsedUrl = parseUrl(url);
    return `${parsedUrl.protocol}://${parsedUrl.host}${parsedUrl.path}`;
  }

  private async getInitialData(): Promise<{
    product: IProductDTO;
    appSettings: IAppSettings;
  }> {
    let product: any;
    let appSettings: IAppSettings;
    const currentUrl = this.getUrlWithoutParams(this.siteStore.location.url);

    if (this.siteStore.isSiteMode() && currentUrl === this.sectionUrl && this.siteStore.seo.isInSEO()) {
      return {product: null, appSettings: null};
    }

    if (
      ((this.siteStore.isEditorMode() || this.siteStore.isPreviewMode()) && this.siteStore.location.path.length > 1) ||
      (this.siteStore.isSiteMode() && currentUrl !== this.sectionUrl)
    ) {
      const fetch = this.productService.getProductBySlug(this.slug, this.externalId);
      const data = await fetch;
      product = data.catalog.product;
      appSettings = data.appSettings;
    } else {
      const fetch = this.productService.getDefaultProduct(this.externalId);
      const data = await fetch;
      product = data.catalog.products.list[0];
      appSettings = data.appSettings;
    }

    return {product, appSettings};
  }

  private getPrevNextProducts(): ProductPagePagination {
    let prevProduct = {} as ProductPagePaginationItem;
    let nextProduct = {} as ProductPagePaginationItem;

    const paginationMap = this.navigationContext.paginationMap;

    const getUrl = (slug: string) => {
      const prefix = `${this.sectionUrl}/${slug}`;
      return (
        prefix +
        (Object.keys(this.siteStore.location.query).length ? `?${queryToString(this.siteStore.location.query)}` : '')
      );
    };

    paginationMap.forEach((slug: string, index: number) => {
      if (slug === this.product.urlPart) {
        if (paginationMap[index - 1]) {
          prevProduct = {
            partialUrl: paginationMap[index - 1],
            fullUrl: getUrl(paginationMap[index - 1]),
          };
        }
        if (paginationMap[index + 1]) {
          nextProduct = {
            partialUrl: paginationMap[index + 1],
            fullUrl: getUrl(paginationMap[index + 1]),
          };
        }
      }
    });

    return {
      nextProduct,
      prevProduct,
    };
  }

  private get slug() {
    const dirtySlug = decodeURIComponent(this.siteStore.location.path[this.siteStore.location.path.length - 1]);
    return dirtySlug.split('?')[0];
  }

  private get quantityRange(): ProdudctQunatityRange {
    const qunatities = QuantityCalculator.getQuantitiesRange(
      this.product,
      this.userInputs.selection as IProductOptionSelection[]
    );
    return {max: qunatities[qunatities.length - 1], min: qunatities[0]};
  }

  private get selectedQuantity(): number {
    return this.userInputs.quantity[0];
  }

  private readonly setPageMetaData = (): void => {
    if (!this.siteStore.isSiteMode()) {
      return;
    }

    let seoData;

    try {
      seoData = JSON.parse((this.product as any).seoJson);
    } catch {
      //
    }

    const productWithPageUrl = {...this.product, pageUrl: this.productUrl};
    const itemData: ProductPageItemData = {
      product: new SeoProductBuilder(productWithPageUrl as any, {
        productPageBaseUrl: this.sectionUrl,
      }).get(),
      legacySeoData: {
        title: this.product.seoTitle,
        description: this.product.seoDescription,
      },
    };

    this.siteStore.seo.renderSEOTags({
      itemType: 'STORES_PRODUCT',
      itemData,
      seoData,
    });
  };

  private readonly pagePath = async (): Promise<StructurePage[]> => {
    if (this.siteStore.isSSR()) {
      return [];
    }

    const siteStructure = await this.siteStore.siteApis.getSiteStructure();
    const path = siteStructure.pages.filter(p => p.isHomePage);

    const navigatedFromPageId = this.navigationContext.pageId;
    if (navigatedFromPageId) {
      const page = siteStructure.pages.find(p => {
        const notHomepage = !p.isHomePage;
        const notSelf = p.id !== this.siteStore.siteApis.currentPage.id;
        const matchRef = p.id === navigatedFromPageId;
        return notHomepage && notSelf && matchRef;
      });
      if (page) {
        page.name = capitalizeFirstLetters(page.name);
        page.url = `${this.siteStore.location.baseUrl}${page.url}`;
        path.push(page);
      }
    }

    path.push({name: this.product.name, url: null, isHomePage: false, id: null});
    path[0].name = this.translations.BREADCRUMBS_HOME;
    path[0].url = this.siteStore.location.baseUrl;
    return path;
  };

  private getNavigationContext(): IStoreFrontNavigationContext {
    let context: IStoreFrontNavigationContext;
    try {
      context = JSON.parse(this.siteStore.storage.local.getItem(STORAGE_PAGINATION_KEY));
    } catch {
      //
    }
    return context || {pageId: undefined, paginationMap: []};
  }

  private get productUrl(): string {
    return `${this.sectionUrl}/${this.slug}`;
  }

  private readonly getSubscriptionPlans = (): ISubscriptionPlan[] => {
    const {subscriptionPlans} = this.product;

    if (!subscriptionPlans || !subscriptionPlans.list) {
      return [];
    }

    return !_.isNil(subscriptionPlans.oneTimePurchase.index)
      ? [
          ...subscriptionPlans.list
            .slice(0, subscriptionPlans.oneTimePurchase.index)
            .map(this.mapSubscriptionDtoToSubscriptionPlan),
          {
            id: undefined,
            name: this.translations.PRODUCT_PAGE_ONE_TIME_PURCHASE_LABEL,
            formattedPrice: this.product.formattedPrice,
            isOneTimePurchase: true,
          },
          ...subscriptionPlans.list
            .slice(subscriptionPlans.oneTimePurchase.index)
            .map(this.mapSubscriptionDtoToSubscriptionPlan),
        ]
      : subscriptionPlans.list.map(this.mapSubscriptionDtoToSubscriptionPlan);
  };

  private mapSubscriptionDtoToSubscriptionPlan(item: IProductDTO['subscriptionPlans']['list'][0]): ISubscriptionPlan {
    return {
      ...item,
      isOneTimePurchase: false,
    };
  }
}
